import apollo from '@utils/graphql';

export const makeQuery = async (queryFile, queryVariables) => (
  await apollo.query({
    query: queryFile,
    variables: queryVariables,
    fetchPolicy: 'no-cache'
  })
);

export const makeMutation = async (queryFile, mutationVariables) => (
  await apollo.mutate({
    mutation: queryFile,
    variables: mutationVariables,
    fetchPolicy: 'no-cache'
  })
);
