<template>
  <div class="animated fadeIn">
    <div v-if="!load">
      <b-alert show variant="danger" v-if="!allowNegotiation">
        {{ $t(`payments.titles.negotiationBlocked`) }}
        <strong>
          {{ $t(`payments.enumNegotiationAllow.${reason}`) }}
        </strong>
      </b-alert>
    </div>
    <b-card body-class="p-0">
      <b-card-header>
        <b-row>
          <b-col cols="8">
            <h4 class="mt-2">
              {{ $t('payments.titles.newNegotiation') }}
            </h4>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="p-0">
        <b-tabs v-model="tabIndex" pills card vertical>
          <b-tab :title="$t('payments.titles.transactions')" :title-link-class="linkClass(0)" data-test="tab-payments-methods">
            <div class="row">
              <div class="col-12">
                <b-alert show variant="warning" class="font-weight-bold">
                  <i class="fa fa-hand-pointer-o" />
                  {{ $t('payments.labels.selectTransactionsNegotiation') }}
                </b-alert>
              </div>

              <driver-negotiation-table-select :driverId="driverId" @selected="setSelected" :disableNegotiation="allowNegotiation" />
            </div>

            <div class="d-flex justify-content-end">
              <div class="d-flex-item">
                <b-button variant="primary" :disabled="disablePaymentsMethods" data-test="btn-payments-methods" @click="goToPayments()">
                  {{ $t('payments.buttons.paymentsMethods') }} <i class="fa fa-arrow-right" />
                </b-button>
              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('payments.titles.paymentsMethods')" :disabled="disablePaymentsMethods" :title-link-class="linkClass(1)">
            <b-form>
              <b-form-row>
                <b-form-group :label="$t('payments.labels.paymentMethod')" label-for="transaction-payment-method" class="col-md-8 col-sm-12">
                  <b-form-select
                    id="transaction-payment-method"
                    v-model="form.methodPayment"
                    :options="form_payment_methods"
                    data-test="select-payment-method"
                  />
                  <b-form-invalid-feedback v-html="$t('payments.labels.mandatoryField')" />
                </b-form-group>
              </b-form-row>

              <b-form-row>
                <b-form-group :label="$t('payments.labels.installments')" class="col-md-4 col-sm-12">
                  <b-form-select v-model="form.installments" data-test="select-parcelas" :disabled="disableParcelas">
                    <b-form-select-option v-for="(intallment, index) in maxInstallment" :key="index" :value="index + 1">
                      {{ index + 1 }}x {{ $t('payments.labels.installments') }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-form-row>

              <b-form-row>
                <b-form-group class="col-md-4 col-sm-12" :label="$t('payments.labels.expireDateFull')" label-for="transaction-expire">
                  <date-picker
                    v-model="form.expired_at"
                    :start-at="this.$moment().toDate().toString()"
                    :not-after="setmaxDaysAfter"
                    :hide="false"
                    data-test="datepicker-expired"
                  />
                </b-form-group>
              </b-form-row>
            </b-form>

            <div class="d-flex justify-content-end mt-5">
              <div class="d-flex-item">
                <b-button variant="secondary" class="mr-1" @click="goToTransactions()">
                  {{ $t('payments.buttons.comeBack') }}
                </b-button>
                <b-button variant="primary" :disabled="disableResume" @click="goToResume()">
                  {{ $t('payments.buttons.resumeNegotiation') }} <i class="fa fa-arrow-right" />
                </b-button>
              </div>
            </div>
          </b-tab>

          <b-tab :title="$t('payments.titles.resumeNegotiation')" :disabled="disableResume" :title-link-class="linkClass(2)">
            <driver-negotiation-resume
              :transactions="transactionsSelected"
              :startDate="form.expired_at"
              :installments="form.installments"
              :paymentMethodType="paymentMethodsType"
              :focused="tabIndex === 2"
              @load="setNegotiationResume"
            >
              <div class="d-flex justify-content-between">
                <small class="text-danger" :style="{ visibility: isCreditCard ? 'visible' : 'hidden'}">
                  * {{ $t('payments.labels.transactionFeeInformation') }}
                </small>
                <b-form-checkbox id="checkbox-1" v-model="confirmResume" name="checkbox-1">
                  {{ $t('payments.labels.confirmInfos') }}
                </b-form-checkbox>
              </div>
            </driver-negotiation-resume>

            <div class="d-flex justify-content-end mt-5">
              <div class="d-flex-item">
                <b-button variant="secondary" class="mr-1" @click="goToPayments()">
                  {{ $t('payments.buttons.comeBack') }}
                </b-button>

                <b-button
                  variant="secondary"
                  class="mr-1 btn btn-success"
                  :disabled="!negotiationResume"
                  @click="printNegotiation()"
                >
                  {{ $t('payments.buttons.printNegotiation') }}
                </b-button>

                <b-button variant="success" :disabled="!confirmResume" @click="createNegotiation()">
                  <b-spinner v-if="negotiationLoad" small />
                  {{ $t('payments.buttons.confirmNegotiation') }}
                </b-button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import NEGOTIATION_ALLOW from '@graphql/negotiation/queries/getNegotiationAllow.gql';
import CREATE_PARENTAL_TRANSACTION from '@graphql/negotiation/mutations/createParentalTransaction.gql';
import GET_MAX_INSTALLMENTS from '@graphql/negotiation/queries/negotiationMaxInstallments.gql';
import DRIVER_PAYMENT_METHODS from '@graphql/payment/queries/get.gql';
import { makeMutation, makeQuery } from '@graphql/middleware'
import { mapGetters } from 'vuex'
export default {
  name: 'NegotiationsNew',
  components: {
    DriverNegotiationTableSelect: () => import('@components/drivers/payment-info/driver-negotiation-table-select'),
    DriverNegotiationResume: () => import('@components/drivers/payment-info/driver-negotiation-resume'),
  },
  data() {
    return {
      driverId: this.$route.params.id,
      tabIndex: 0,
      disable: {
        pagamentos: true,
        resumo: true
      },
      form_payment_methods: [],
      paymentMethodsItems: [],
      paymentMethodsType: '',
      optionsCycle: [
        { value: 0, text: 'Nenhum', disabled: true },
        { value: 7, text: 'Semanal - 7 dias' },
        { value: 30, text: 'Mensal - 30 dias' },
      ],
      disableParcelas: false,
      maxInstallment: 1,
      form: {
        methodPayment: '',
        installments: 1,
        expired_at: null,
        cycle: 7,
      },
      negotiationResume: null,
      transactionsSelected: [],
      confirmResume: false,
      negotiationLoad: false,
      maxDaysAfter: 7,
      reason: '',
      allowNegotiation: true,
      load: true,
    }
  },
  computed: {
     ...mapGetters('user', ['findGroups']),
    disablePaymentsMethods: function () {
      if (this.transactionsSelected.length === 0) {
        return true
      }
      return false
    },
    disableResume: function () {
      if (!!this.form.methodPayment && !!this.form.installments && !!this.form.expired_at) {
        return false
      }
      return true
    },
    setmaxDaysAfter: function () {
      return this.$moment().add(this.maxDaysAfter, 'day').format('YYYY-MM-DD')
    },
    isCreditCard: function() {
      return this.paymentMethodsType === 'credit_card'
    }
  },
  watch: {
    'form.methodPayment': function (newVal) {
      let newPaymentMethod =  this.paymentMethodsItems.find(item => item.id === newVal);
      this.paymentMethodsType = newPaymentMethod.type;
      if (['boleto','pix','oxxo'].includes(newPaymentMethod.type)) {
        this.form.installments = 1;
        this.maxDaysAfter = 5
      } else {
        this.maxDaysAfter = 1;
      }
      this.getMaxInstallments();
    }
  },
  apollo: {
    payment_method: {
      query: DRIVER_PAYMENT_METHODS,
      variables() {
        return {

          driver: this.$route.params.id,
        };
      },
      update: data => data.paymentMethods,
      result(data) {
        if (!data.loading) {
          let _items = [];
          let _this = this;
          this.paymentMethodsItems = data.data.paymentMethods.items;
          this.paymentMethodsItems
          .forEach(item => {
            _items.push({
              value: item.id,
              text:
                  item.type === 'credit_card'
                    ? `${_this.$t('payments.enumType.card').toUpperCase()}: ${item.cc_holder_name} (${item.cc_brand.toUpperCase()}: ${item.cc_number})`
                    : `${_this.getPaymentMethodType(item.type)}: ${item.driver.name}`,
            });
          });
          this.form_payment_methods = _items;
          this.form.methodPayment = this.paymentMethodsItems.find(item => item.type !== 'credit_card').id;
        }
      },
    }
  },
  mounted () {
    if (!this.findGroups('ACL:BUTTON:NEGOTIATION')) {
      this.$router.push(`/drivers/profile/${this.driverId}`);
    }
    this.getAllow()
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-info']
      }
    },
    goToTransactions () {
      this.tabIndex = 0;
    },
    goToPayments () {
      this.tabIndex = 1;
      this.getMaxInstallments();
    },
    goToResume () {
      this.tabIndex = 2;
    },
    setSelected (transactions) {
      this.transactionsSelected = transactions;
    },
    setNegotiationResume (resume) {
      this.negotiationResume = resume;
    },
    getAllow () {
      this.load = true
      makeQuery(
        NEGOTIATION_ALLOW,
        { 
          driver: this.$route.params.id
        }
      )
      .then((res) => {
        const { getAllowNegotiation } = res.data
        this.reason = getAllowNegotiation.reason
        this.allowNegotiation = getAllowNegotiation.result
        this.load = false
      })
      .catch((err) => {
        this.$swal({
          type: 'error',
          title: this.$t('payments.titles.errorNegotiationAllow'),
          showConfirmButton: false,
          timer: 2500,
        })
        console.error(err)
      })
    },
    printNegotiation() {
      const profile = this.$store.getters['driver/header'];

      const printNegotiation = {
        driverName: profile.name ?? '',
        plan: profile.booking?.plan?.name ?? '',
        license_plate: profile.booking?.car?.license_plate ?? '',
        model: profile.booking?.car?.model?.name ?? '',
        transactions: this.transactionsSelected,
        negotiation_resume: this.negotiationResume,
        installments: this.form.installments,
        start_date: this.form.expired_at,
        payment_method: this.paymentMethodsType,
      };

      localStorage.setItem('kovi.printNegotiation', JSON.stringify(printNegotiation));
      window.open(`${window.location.origin}/negotiations/print`, '_blank');
    },
    createNegotiation () {
      this.negotiationLoad = true
      makeMutation(
        CREATE_PARENTAL_TRANSACTION,
        { 
          transactions: this.transactionsSelected.map(e => e.id),
          installments: this.form.installments,
          start_date: this.form.expired_at,
          payment_method: this.form.methodPayment
        }
      )
      .then(() => {
        this.showSuccessModal();
        this.negotiationLoad = false
      })
      .catch(() => {
        this.showErrorModal();
        this.negotiationLoad = false
      })
    },
    getMaxInstallments () {
      const paymentMethod = this.paymentMethodsItems.find(item => item.id === this.form.methodPayment)
      this.disableParcelas = true
      makeQuery(
        GET_MAX_INSTALLMENTS,
        { 
          transactions: this.transactionsSelected.map(e => e.id),
          payment_method_type: paymentMethod.type.toUpperCase()
        }
      )
      .then(({ data: { getNegotiationMaxInstallments } }) => {
        this.maxInstallment = getNegotiationMaxInstallments.totalInstallment;
        this.disableParcelas = false;
      })
    },
    getPaymentMethodType (type) {
      switch (type) {
        case 'boleto':
          return this.$t('payments.enumType.billet').toUpperCase()
        case 'credit_card':
          return this.$t('payments.enumType.card').toUpperCase()
        case 'pix':
          return this.$t('payments.enumType.pix').toUpperCase()
        case 'oxxo':
          return this.$t('payments.enumType.oxxo').toUpperCase()
        default:
          return ' - '
      }
    },
    showSuccessModal() {
      this.$swal({
        type: 'success',
        title: this.$t('payments.titles.successNegotiation'),
        showConfirmButton: false,
        timer: 2500,
      }).then(() => {
        this.$router.push(`/drivers/profile/${this.$route.params.id}/negotiations`)
      });
    },
    showErrorModal() {
      this.$swal({
        type: 'error',
        title: this.$t('payments.titles.errorNegotiation'),
        showConfirmButton: false,
        timer: 2500,
      })
    }
  }
}
</script>
