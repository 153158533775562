var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      !_vm.load
        ? _c(
            "div",
            [
              !_vm.allowNegotiation
                ? _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t(`payments.titles.negotiationBlocked`)) +
                        " "
                    ),
                    _c("strong", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              `payments.enumNegotiationAllow.${_vm.reason}`
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-card",
        { attrs: { "body-class": "p-0" } },
        [
          _c(
            "b-card-header",
            [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "8" } }, [
                    _c("h4", { staticClass: "mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("payments.titles.newNegotiation")) +
                          " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-body",
            { staticClass: "p-0" },
            [
              _c(
                "b-tabs",
                {
                  attrs: { pills: "", card: "", vertical: "" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t("payments.titles.transactions"),
                        "title-link-class": _vm.linkClass(0),
                        "data-test": "tab-payments-methods",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "b-alert",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { show: "", variant: "warning" },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-hand-pointer-o",
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.labels.selectTransactionsNegotiation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("driver-negotiation-table-select", {
                            attrs: {
                              driverId: _vm.driverId,
                              disableNegotiation: _vm.allowNegotiation,
                            },
                            on: { selected: _vm.setSelected },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex-item" },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  variant: "primary",
                                  disabled: _vm.disablePaymentsMethods,
                                  "data-test": "btn-payments-methods",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.goToPayments()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("payments.buttons.paymentsMethods")
                                    ) +
                                    " "
                                ),
                                _c("i", { staticClass: "fa fa-arrow-right" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t("payments.titles.paymentsMethods"),
                        disabled: _vm.disablePaymentsMethods,
                        "title-link-class": _vm.linkClass(1),
                      },
                    },
                    [
                      _c(
                        "b-form",
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-8 col-sm-12",
                                  attrs: {
                                    label: _vm.$t(
                                      "payments.labels.paymentMethod"
                                    ),
                                    "label-for": "transaction-payment-method",
                                  },
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "transaction-payment-method",
                                      options: _vm.form_payment_methods,
                                      "data-test": "select-payment-method",
                                    },
                                    model: {
                                      value: _vm.form.methodPayment,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "methodPayment", $$v)
                                      },
                                      expression: "form.methodPayment",
                                    },
                                  }),
                                  _c("b-form-invalid-feedback", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("payments.labels.mandatoryField")
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-4 col-sm-12",
                                  attrs: {
                                    label: _vm.$t(
                                      "payments.labels.installments"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      attrs: {
                                        "data-test": "select-parcelas",
                                        disabled: _vm.disableParcelas,
                                      },
                                      model: {
                                        value: _vm.form.installments,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "installments",
                                            $$v
                                          )
                                        },
                                        expression: "form.installments",
                                      },
                                    },
                                    _vm._l(
                                      _vm.maxInstallment,
                                      function (intallment, index) {
                                        return _c(
                                          "b-form-select-option",
                                          {
                                            key: index,
                                            attrs: { value: index + 1 },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(index + 1) +
                                                "x " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "payments.labels.installments"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "col-md-4 col-sm-12",
                                  attrs: {
                                    label: _vm.$t(
                                      "payments.labels.expireDateFull"
                                    ),
                                    "label-for": "transaction-expire",
                                  },
                                },
                                [
                                  _c("date-picker", {
                                    attrs: {
                                      "start-at": this.$moment()
                                        .toDate()
                                        .toString(),
                                      "not-after": _vm.setmaxDaysAfter,
                                      hide: false,
                                      "data-test": "datepicker-expired",
                                    },
                                    model: {
                                      value: _vm.form.expired_at,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "expired_at", $$v)
                                      },
                                      expression: "form.expired_at",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end mt-5" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex-item" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-1",
                                  attrs: { variant: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToTransactions()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("payments.buttons.comeBack")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.disableResume,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToResume()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.buttons.resumeNegotiation"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("i", { staticClass: "fa fa-arrow-right" }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      attrs: {
                        title: _vm.$t("payments.titles.resumeNegotiation"),
                        disabled: _vm.disableResume,
                        "title-link-class": _vm.linkClass(2),
                      },
                    },
                    [
                      _c(
                        "driver-negotiation-resume",
                        {
                          attrs: {
                            transactions: _vm.transactionsSelected,
                            startDate: _vm.form.expired_at,
                            installments: _vm.form.installments,
                            paymentMethodType: _vm.paymentMethodsType,
                            focused: _vm.tabIndex === 2,
                          },
                          on: { load: _vm.setNegotiationResume },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c(
                                "small",
                                {
                                  staticClass: "text-danger",
                                  style: {
                                    visibility: _vm.isCreditCard
                                      ? "visible"
                                      : "hidden",
                                  },
                                },
                                [
                                  _vm._v(
                                    " * " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.labels.transactionFeeInformation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    id: "checkbox-1",
                                    name: "checkbox-1",
                                  },
                                  model: {
                                    value: _vm.confirmResume,
                                    callback: function ($$v) {
                                      _vm.confirmResume = $$v
                                    },
                                    expression: "confirmResume",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("payments.labels.confirmInfos")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-end mt-5" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex-item" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-1",
                                  attrs: { variant: "secondary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToPayments()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("payments.buttons.comeBack")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mr-1 btn btn-success",
                                  attrs: {
                                    variant: "secondary",
                                    disabled: !_vm.negotiationResume,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.printNegotiation()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.buttons.printNegotiation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "success",
                                    disabled: !_vm.confirmResume,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.createNegotiation()
                                    },
                                  },
                                },
                                [
                                  _vm.negotiationLoad
                                    ? _c("b-spinner", { attrs: { small: "" } })
                                    : _vm._e(),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.buttons.confirmNegotiation"
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }